<template>
  <page-container>
    <v-img :src="require('@/assets/img/site/about/banner-1.jpg')"></v-img>
    <div class="text-center" style="margin-top: 2rem">
      <div class="pa-4 text-justify" :style="$vuetify.breakpoint.mdAndUp ? {
        fontSize: '18px',
        lineHeight: '2rem'
      } : {
        fontSize: '14px',
        lineHeight: '1.5rem'
      }">
        <span class="capital-first-letter">G</span>outam <span class="capital-first-letter">G</span>hosal, an
        eminent personality in the world of Bengali music, was born in Raghunathganj (Murshidabad)
        on July 3, 1964. His origin was rooted in a feudal, orthodox joint family of Ghosals in Senda.
        Goutam Ghosal had spent most of his childhood days in this remote and lesser-known village of Murshidabad. His
        predecessors were regarded as 'Jotdar'. Strangely enough, Goutam's father Sri Ganga Prasad Ghosal, who was a
        school teacher by profession, had great faith in the left-wing political ideology. He was free of so-called
        superstitions and had a big heart and supportive mind for all good initiatives. On the contrary, his mother, Smt.
        Usha Rani Ghosal was deeply concerned with every welfare issue and even the trifles of their family.
        With her highly noble and unorthodox mindset, she made a great contribution in the formative years of
        her son. She, being a brilliant student, stood first in the School Final Exam among girls in Murshidabad District.
        Later she did her Master's in Philosophy and became the Headmistress of a Higher Secondary School.
        <br /><br />
        <v-img :src="require('@/assets/img/site/about/1.jpg')" :width="$vuetify.breakpoint.mdAndUp? '30%' : $vuetify.breakpoint.smOnly? '50%' : '100%'"
          style="float: left;" class="mr-6 mb-6 image-blurred-edge"></v-img>
        Smt. Usha Rani was the first to read about the keen interest in music in Goutam Ghosal although there was no early
        reference of this art being inculcated in their families. She lost no time to buy him a harmonium when he was only
        twelve. She also made him complete his Primary and Higher Secondary Exams from Domkal Bhabataran Bohumukhi Uchcha Bidyalay
        located in the suburb of Murshidabad. An ever first boy throughout his school career, Goutam Ghosal later
        completed his Honours and Master's degree in English literature from Kalyani University and entered a school service as
        an assistant teacher in the year of 1989. But he left his well-settled government job voluntarily after a stint of
        25 years for a larger call of life.
        <br /><br />
        This much about Goutam Ghosal is however just a slice of his formative years. But as Rome was not built in a day,
        the making of Goutam Ghosal had to embrace a roller coaster journey and he enjoyed dealing with it always with a
        beaming smile. Beginning with Gurus like Shri Nitya Gopal Chakraborty, Ustad Daud Khan and Smt. Aparna Deb for a
        short period of time, Goutam Ghosal's rigorous musical training (talim) continued along with Bidushi Sanjukta Ghosh of
        Patiala Gharana. But his thirst for music could not limit him there. Goutam Ghosal keeps learning directly or indirectly
        from whoever he comes in contact with ranging from Indian Classical to Folk music to fill his casket with rare
        gems. This way he says he is indebted to so many gurus even including some luminaries without having a clue of
        his invisible tutelage as such.
        <br /><br />
        <v-img :src="require('@/assets/img/site/about/2.jpg')" :width="$vuetify.breakpoint.mdAndUp ? '45%' : '100%'"
          style="float: right;" class="ml-6 mb-6 image-blurred-edge"></v-img>
        Goutam Ghosal's persona is versatile. He is a celebrated Vocalist, a Lyricist, a Composer and a Music Designer at the
        same time. But the journey as a professional musician in the city of Kolkata was not understandably smooth for a
        young lad from the suburbs. Goutam Ghosal practically had no godfather, nor had any intense urge either for a quick name
        or fame. He has however been always with a friendly air around. Thus Goutam Ghosal and Sreekanta Acharya, a
        knowledgeable and exponent singer, soon became friends. And it is that fellow musician without whose spontaneous
        endeavour making a boost, the Bengali music industry would not have perhaps witnessed the timely appearance of a
        versatile musician like Goutam Ghosal.
        <br /><br />
        More than half a dozen Albums of Bengali Songs are already in Goutam Ghosal's credit like Megh Pakhi (2000), Bandhu (2001),
        Roddur (2002), Nana Ronge (2004), Antomil (2012), Majlis (2017), Kamal Geeti (2018) and Bani Bandana (2019). Not only
        that, many of his mind-blowing lyrics and compositions have enriched a good many albums of some senior and
        contemporary vocalists like Nirmala Mishra, Amit Kumar, Anuradha Parawal, Sreekanta Acharya, Shubhamita Banerjee, Jayati
        Chakraborty, Lopamudra Mitra, Indrani Sen, Shampa Kundu, Pallab Ghosh, and so on. Besides a number of
        upcoming, promising singers from Bengal have been getting opportunities to sing his compositions.
        <br /><br />
        <v-img :src="require('@/assets/img/site/about/3.png')" :width="$vuetify.breakpoint.mdAndUp ? '30%' : $vuetify.breakpoint.smOnly? '50%' : '100%'"
          style="float: left;" class="mr-6 mb-6 image-blurred-edge"></v-img>
        In the year 2006, Goutam Ghosal together with his musician wife Shiuli Ghosal set up this academy named “Meghpakhi Music
        Academy”. Goutam Ghosal's main objective was to induce the contemporary as well as newer generations with
        his very own thoughts and contemplations about the Indian perennial music and also about his own offbeat lyrics
        and compositions of different tastes and delicacies in keeping with the modern trend of Bengali songs.
        <br /><br />
        A popular face in TV shows, Goutam Ghosal also got honoured as Champion in a Kolkata Doordarshan programme, "Gaan Shudhu
        Gaan" in the early days of his professional career. He also received "Pratidin Tele Sammam Award" for making the Title
        Song and singing for the TV Serial, "Haat Baralei Bandhu". Goutam Ghosal also left his footprint in the arena of films
        with his lyrics, compositions and background scores for Bengali movies, "Chhaye Chhuti" and "Bye Bye Bangkok".
        This duo hit the box office winning the hearts of the film buffs. Apart from performing in numberless musical
        shows and concerts in different parts of India, Goutam Ghosal also has travelled abroad with his music repertoire in
        places like Qatar, Sydney, London, Dhaka, and many more.
        <br /><br />
        Nowadays, many Bengali music lovers often rue about the fate of all timeless lyrics and their compositions after
        the stalwarts of yesteryears like Salil Choudhury, Jatileshwar Mukhopadhyay and a few others. But the way Goutam Ghosal
        is fighting back letting their legacy not die, the cherished trend of Bengali Songs may have a further lease of
        life through the upcoming brigade of followers around Goutam Ghosal and his school of thoughts.
        <br /><br />
        <v-img :src="require('@/assets/img/site/about/4.jpg')" :width="$vuetify.breakpoint.mdAndUp ? '30%' : $vuetify.breakpoint.smOnly? '50%' : '100%'"
          style="float: right;" class="ml-6 mb-6 image-blurred-edge"></v-img>
        Beyond his musicianship, Goutam Ghosal also maintains a great interest in reading books, penning literary stuff, drawing
        pictures and caring for plants, trees and stray animals. These apart, he is not only a frequenter of theatre shows, he has
        been arranging background music for most of the leading theatre groups for long. No less than fifty dramas have already
        been staged with his music scores. Like his father, Goutam Ghosal, unsurprisingly, has been politically motivated by
        left-wing ideology and was involved in many political affairs as a grown-up youth. He also conducts a number of
        social activities with his academy students.
        <br /><br />
        As it is, the soil of Bengali Music and Literature is ever verdant with green grasses, but with the footfall of
        Goutam Ghosal in its cultural map the grasses have perhaps been greener as he tried to redefine the Bengali songs
        and their style of singing with a very special cuisine and signature of his own genius. And to make it all happen
        no doubt that he has had to burn himself with the fire of his own zeal. So, what more, if Time grants him a seat
        among the cult figures who fought relentlessly for the golden glory of Bengali Songs and the proud tradition
        thereof for centuries now.
      </div>
    </div>
  </page-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>

<style lang="scss">
.image-blurred-edge {
  .v-responsive__content {
    box-shadow: 0 0 8px 8px #DDD inset;
  }
}
</style>
